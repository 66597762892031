@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%; /* #ffffff White - Background */
    --foreground: 0 0% 14%; /* #232323 TextPrimary - Primary text */
    --primary-light: 218 90% 96%; /* #007AC3 BlueLight */
    --gray-super-light: 0 0% 83%; /* #D3D3D3 Gray Super Light */
    --red-light: 0 100% 94%; /* #FFE0E0 BlueLight */
    --card: 0 0% 100%; /* #ffffff White - Card background color */
    --card-foreground: 0 0% 14%; /* #232323 TextPrimary - Card text color */
    --popover: 0 0% 100%; /* #ffffff White - Popover background color */
    --popover-foreground: 0 0% 14%; /* #232323 TextPrimary - Popover text color */
    --primary: 202 100% 38%; /* #007AC3 BlueActive - Primary color */
    --primary-foreground: 210 12% 97%; /* #F6F7F8 BgGrey - Primary text color */
    --secondary: 210 12% 97%; /* #F6F7F8 BgGrey - Secondary background color */
    --secondary-foreground: 0 0% 28%; /* #474747 TextSecondary - Secondary text color */
    --alternative: 81.2 70.9% 43.1%; /* #85BC20 GreenActive - Alternative color */
    --warning-foreground: 20 90% 51%; /* #F25C11 orange - Warning color */
    --warning: 33 100% 92%; /* #ffecd4 orange - Warning background */
    --muted: 210 12% 97%; /* #F6F7F8 bgGrey - Muted background color */
    --muted-foreground: 0 0% 58%; /* #949494 TextAdditional - Muted text color */
    --accent: 210 12% 97%; /* #F6F7F8 bgGrey - Accent background color */
    --accent-foreground: 0 0% 28%; /* #474747 TextSecondary - Accent text color */
    --destructive: 356 79% 51%; /* #E5202E Error - Destructive (error) color */
    --destructive-foreground: 210 12% 97%; /* #F6F7F8 BgGrey - Destructive text color */
    --border: 210 12% 97%; /* #F6F7F8 BorderGrey - Border color */
    --input: 0 0% 58%; /* #949494 TextAdditional - InputText color */
    --ring: 202 100% 38%; /* #007AC3 BlueActive - FocusRing color */
    --radius: 0.5rem; /* Radius for rounded corners */
  }
}

@layer base {
  * {
    @apply border-border scrollbar-thin;
  }
  body {
    @apply bg-background text-foreground;
    font-family:
      "Fira Sans",
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  h1 {
    @apply text-2xl font-bold md:text-3xl;
  }

  h2 {
    @apply text-xl font-semibold md:text-2xl;
  }

  h3 {
    @apply text-base font-semibold md:text-xl;
  }

  h4 {
    @apply text-sm font-semibold md:text-base;
  }

  h5 {
    @apply text-xs font-normal md:text-sm;
  }

  a {
    @apply text-primary;
  }

  input[type="date"],
  input[type="time"] {
    position: relative;
  }

  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="time"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

@layer components {
  .my-react-select-container .my-react-select__control {
    @apply rounded-md border border-input bg-background text-sm hover:border-input dark:border-muted-foreground dark:hover:border-muted-foreground;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-input ring-2 ring-ring ring-offset-2 ring-offset-background transition-colors hover:border-input;
  }

  .my-react-select-container .my-react-select__control--is-disabled {
    @apply pointer-events-auto cursor-not-allowed opacity-70;
  }

  .my-react-select-container .my-react-select__menu {
    @apply z-10 border border-input bg-background;
  }

  .my-react-select-container .my-react-select__option {
    @apply flex items-center gap-3 bg-background text-sm text-secondary-foreground hover:bg-accent hover:opacity-80;
  }

  /*.my-react-select-container .my-react-select__option--is-focused,*/
  .my-react-select-container .my-react-select__option--is-selected {
    @apply bg-primary/10 font-medium hover:bg-primary/10;
  }

  .my-react-select-container .my-react-select__option--is-disabled {
     @apply bg-accent hover:bg-accent cursor-not-allowed
  }

  .my-react-select-container .my-react-select__indicator-separator {
    @apply hidden;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply flex items-center gap-3 text-secondary-foreground;
  }

  /* Multi-value container and labels */
  .my-react-select-container .my-react-select__multi-value {
    @apply rounded-md border border-primary bg-background;
  }

  .my-react-select-container .my-react-select__multi-value__label {
    @apply text-primary;
  }

  /* Multi-value remove button and SVG */
  .my-react-select-container .my-react-select__multi-value__remove {
    @apply rounded-full hover:bg-white;
  }

  .my-react-select-container .my-react-select__multi-value__remove .css-tj5bde-Svg {
    @apply text-muted-foreground;
  }

  /* Clear and dropdown indicator */
  .my-react-select-container .my-react-select__clear-indicator,
  .my-react-select-container .my-react-select__dropdown-indicator {
    @apply h-4 w-4 p-0 text-muted-foreground;
  }

  .my-react-select-container .my-react-select__dropdown-indicator {
    @apply mr-1;
  }

  .my-react-select-container .my-react-select__clear-indicator:hover,
  .my-react-select-container .my-react-select__dropdown-indicator:hover {
    @apply cursor-pointer text-muted-foreground;
  }

  .my-react-select-container .my-react-select__clear-indicator .css-tj5bde-Svg,
  .my-react-select-container .my-react-select__dropdown-indicator .css-tj5bde-Svg {
    @apply fill-current;
  }
}
